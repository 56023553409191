import { useState } from 'react'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Circle,
  Divider,
  Flex,
  HStack,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconQuestionMark } from '@tabler/icons-react'
import { useCloseDemandMutation, useOffersQuery } from 'generated/generated-graphql'
import { isEmpty } from 'lodash'
import { GridTable } from 'components/table/GridTable'
import { Modal } from 'components/ui/Modal'
import { getLocaleFormattedPrice } from 'utils/price'
import { useSuccessToast } from 'utils/toast'

enum SelectWinnerModalSteps {
  OfferList,
  ConfirmSelection,
  ConfirmClosingWithoutWinner,
}

const SelectWinnerModalTitleMapper: Record<SelectWinnerModalSteps, string> = {
  [SelectWinnerModalSteps.OfferList]: 'Ukončiť dopyt a vybrať ponuku',
  [SelectWinnerModalSteps.ConfirmSelection]: 'Potvrdzujem výber ponuky, ktorú som označil',
  [SelectWinnerModalSteps.ConfirmClosingWithoutWinner]:
    'Naozaj chcete ukončiť dopyt bez výberu ponuky?',
}

interface OfferTemplateDetailModalProps extends Omit<ModalProps, 'children'> {
  demandId: number
  preselectedOfferIds?: string[]
  isOpen: boolean
  onClose: () => void
  hasOffers: boolean
  onSubmitSuccess?: () => void
}

export const SelectWinnerModal = ({
  isOpen,
  onClose,
  demandId,
  hasOffers,
  preselectedOfferIds,
  onSubmitSuccess,
}: OfferTemplateDetailModalProps) => {
  const [currentStep, setCurrentStep] = useState(
    hasOffers
      ? SelectWinnerModalSteps.OfferList
      : SelectWinnerModalSteps.ConfirmClosingWithoutWinner,
  )
  const [selectedOfferIds, setSelectedOfferIds] = useState<string[]>(preselectedOfferIds ?? [])
  const successToast = useSuccessToast()
  const { data: offersListData, loading } = useOffersQuery({
    variables: { input: { demandId } },
    fetchPolicy: 'cache-and-network',
    skip: !hasOffers,
  })
  const [closeDemand, { loading: isDemandClosing }] = useCloseDemandMutation()

  const onSubmit = async () => {
    const response = await closeDemand({
      variables: {
        input: { id: demandId, selectedOfferIds: selectedOfferIds?.map((id) => Number(id)) },
      },
    })

    if (!response.errors) {
      successToast({ title: 'Ukončenie dopytu', description: 'Ukončenie bolo úspešné.' })
      onSubmitSuccess?.()
      onClose()
    }
  }
  console.log('selectedOfferIds', selectedOfferIds)
  return (
    <Modal
      width={currentStep === SelectWinnerModalSteps.OfferList ? '800px' : '480px'}
      hasCloseIcon={currentStep === SelectWinnerModalSteps.OfferList}
      title={
        hasOffers
          ? SelectWinnerModalTitleMapper[currentStep]
          : 'Váš dopyt ešte nemá žiadne ponuky! Chcete ho napriek tomu ukončiť?'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {currentStep === SelectWinnerModalSteps.OfferList && (
        <VStack w="full" align="start" spacing="24px" divider={<Divider borderColor="#CCCCCC" />}>
          <VStack align="start">
            <Text>
              Dobré rozhodnutie pre konkrétnu ponuku predpokladá dôkladné porovnanie ponúk a ďalšiu
              komunikáciu s dodávateľmi, ktorí ponuky predkladajú.
            </Text>
            <Flex backgroundColor="brand.blue10" p="16px" gap="24px" borderRadius="12px">
              <Circle size="24px" bg="brand.blue100">
                <IconQuestionMark size="20px" color="white" stroke={1.5} />
              </Circle>
              <Text>
                Vľavo označíte svojho dodávateľa s najlepšou ponukou a potvrdíte jeho výber
                kliknutím na <strong>“Potvrdiť výber”</strong>.<br />
                <strong>“Ukončiť dopyt bez výberu ponuky”</strong> - znamená pozastavenie, odobratie
                dopytu. Tvorba každej ponuky sa odvíja od veľkosti projektu a pre firmy znamená čas
                a peniaze. Preto včasné potvrdenie ponuky alebo ukončenie dopytu pomáha všetkým
                stranám, ďakujeme.
              </Text>
            </Flex>
          </VStack>

          <VStack w="full" align="start">
            <Text fontSize="16px" fontFamily="DM Sans SemiBold">
              Zoznam ponúk
            </Text>
            <CheckboxGroup
              onChange={(offerIds) => {
                setSelectedOfferIds(offerIds as string[])
              }}
              value={selectedOfferIds}
            >
              <GridTable
                data={offersListData?.offers ?? []}
                isLoading={loading}
                headerStyles={{ templateColumns: '30px 1fr 1fr .5fr' }}
                rowStyles={{ templateColumns: '30px 1fr 1fr .5fr' }}
                columns={[
                  {
                    header: '',
                    cell: ({ id }) => <Checkbox value={String(id)} />,
                  },
                  {
                    header: 'Dodávateľ',
                    cell: ({ contractor }) => (
                      <Text fontFamily="DM Sans SemiBold" color="brand.blue100">
                        {contractor.companyName ??
                          `${contractor?.firstName} ${contractor?.lastName}`}
                      </Text>
                    ),
                  },
                  {
                    header: 'Názov ponuky',
                    cell: ({ name }) => name,
                  },
                  {
                    header: 'Cena',
                    cell: ({ priceList, approximatePrice }) =>
                      priceList ? (
                        <HStack>
                          <Text>{getLocaleFormattedPrice(priceList.totalAmount)}</Text>
                          {priceList?.totalAmountWithTax && (
                            <>
                              <Text>/</Text>
                              <Text>
                                {getLocaleFormattedPrice(Number(priceList.totalAmountWithTax))}
                              </Text>
                            </>
                          )}
                        </HStack>
                      ) : (
                        <Text>
                          {approximatePrice && getLocaleFormattedPrice(approximatePrice)}{' '}
                        </Text>
                      ),
                  },
                ]}
              />
            </CheckboxGroup>
          </VStack>
          <Flex w="full" justify="space-between">
            <HStack spacing="16px">
              <Button variant="outline" onClick={onClose}>
                Zatvoriť
              </Button>
              <Button
                isDisabled={isEmpty(selectedOfferIds)}
                onClick={() => {
                  setCurrentStep(SelectWinnerModalSteps.ConfirmSelection)
                }}
              >
                Potvrdiť výber
              </Button>
            </HStack>
            <Button
              variant="outline"
              onClick={() => {
                setCurrentStep(SelectWinnerModalSteps.ConfirmClosingWithoutWinner)
              }}
            >
              Ukončiť dopyt bez vyberu ponuky
            </Button>
          </Flex>
        </VStack>
      )}
      {currentStep === SelectWinnerModalSteps.ConfirmSelection && (
        <VStack w="full" align="start" spacing="24px" divider={<Divider borderColor="#CCCCCC" />}>
          <Text>
            Potvrdením oznamujete dodávateľovi, že ste sa rozhodli pre jeho ponuku. <br />
            <br />
            Po dohode s dodávateľom môžete ponuku vytlačiť, podpísať a doručiť mu ako záväznú
            objednávku.
          </Text>
          <HStack w="full" spacing="16px">
            <Button
              w="full"
              variant="outline"
              isDisabled={isDemandClosing}
              isLoading={isDemandClosing}
              onClick={() => {
                setCurrentStep(SelectWinnerModalSteps.OfferList)
              }}
            >
              Späť
            </Button>
            <Button
              w="full"
              isDisabled={isDemandClosing}
              isLoading={isDemandClosing}
              onClick={() => {
                void onSubmit()
              }}
            >
              Potvrdiť
            </Button>
          </HStack>
        </VStack>
      )}
      {currentStep === SelectWinnerModalSteps.ConfirmClosingWithoutWinner && (
        <VStack w="full" align="start" spacing="24px" divider={<Divider borderColor="#CCCCCC" />}>
          <Text>Ukončené dopyty sa nedajú obnoviť! Presunú sa do archívu.</Text>
          <HStack w="full" spacing="16px">
            <Button
              w="full"
              variant="outline"
              isDisabled={isDemandClosing}
              isLoading={isDemandClosing}
              onClick={() => {
                if (!hasOffers) {
                  onClose()
                }
                if (hasOffers) {
                  setCurrentStep(SelectWinnerModalSteps.OfferList)
                }
              }}
            >
              Späť
            </Button>
            <Button
              w="full"
              isDisabled={isDemandClosing}
              isLoading={isDemandClosing}
              onClick={() => {
                void onSubmit()
              }}
            >
              Ukončiť
            </Button>
          </HStack>
        </VStack>
      )}
    </Modal>
  )
}
