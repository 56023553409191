export enum DemandTimeHorizon {
  Now = 'Now',
  Unknown = 'Unknown',
  In2Weeks = 'In2Weeks',
  In4Weeks = 'In4Weeks',
  In3Months = 'In3Months',
  In6Months = 'In6Months',
  In12Months = 'In12Months',
}

export const DemandTimeHorizonLabelMapper: Record<DemandTimeHorizon, string> = {
  [DemandTimeHorizon.Now]: 'Ihned',
  [DemandTimeHorizon.Unknown]: 'Ešte neviem',
  [DemandTimeHorizon.In2Weeks]: 'Do 3 týždňov',
  [DemandTimeHorizon.In4Weeks]: 'Do 4 týždňov',
  [DemandTimeHorizon.In3Months]: 'Do 3 mesiacov',
  [DemandTimeHorizon.In6Months]: 'Do 6 mesiacov',
  [DemandTimeHorizon.In12Months]: 'Do 1 roka',
}

export const DemandTimeHorizonDropdownOptions = [
  { label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.Now], value: DemandTimeHorizon.Now },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.Unknown],
    value: DemandTimeHorizon.Unknown,
  },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.In2Weeks],
    value: DemandTimeHorizon.In2Weeks,
  },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.In4Weeks],
    value: DemandTimeHorizon.In4Weeks,
  },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.In3Months],
    value: DemandTimeHorizon.In3Months,
  },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.In6Months],
    value: DemandTimeHorizon.In6Months,
  },
  {
    label: DemandTimeHorizonLabelMapper[DemandTimeHorizon.In12Months],
    value: DemandTimeHorizon.In12Months,
  },
]
