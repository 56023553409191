import { Box } from '@chakra-ui/react'

export const GoogleEmbedMap = ({ googlePlacesId }: { googlePlacesId: string }) => (
  <Box w="full" h="300px">
    <iframe
      title="map"
      width="100%"
      height="300px"
      style={{ border: 0, borderRadius: '12px' }}
      loading="lazy"
      allowFullScreen
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}
    &q=place_id:${googlePlacesId}`}
    />
  </Box>
)
