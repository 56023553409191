import * as React from 'react'
import {
  BoxProps,
  Flex,
  Grid,
  GridItem,
  GridProps,
  SimpleGrid,
  SimpleGridProps,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import { DemandListingType, DemandType } from 'generated/generated-graphql'
import { useIsOnMobile } from 'hooks/useIsOnMobile'

export interface ColumnProps<TData> {
  header: string | (() => React.ReactNode)
  onHeaderClick?: () => void
  cell: (rowData: TData) => React.ReactNode
  label?: string
  subLabel?: string
  columnStyles?: BoxProps
  isActionColumn?: boolean
}

interface DefaultDataProps {
  id: number | string
  listingType?: DemandListingType | null
}

export interface TableProps<TData> {
  isLoading: boolean
  data: TData[]
  columns: ColumnProps<TData>[]
  onRowClick?: (rowData: TData) => void
  rowStyles?: GridProps
  headerStyles?: GridProps
  highlightedRowId?: number
  mobileContent?: React.ReactNode
  mobileTableGridProps?: SimpleGridProps
  highlighEmergencyDemands?: boolean
  useMobileTable?: boolean
}

export const GridTable = <TData extends DefaultDataProps>({
  isLoading,
  data,
  columns,
  onRowClick,
  rowStyles,
  headerStyles,
  highlightedRowId,
  mobileTableGridProps,
  mobileContent,
  highlighEmergencyDemands,
  useMobileTable = true,
}: TableProps<TData>) => {
  const isOnMobile = useIsOnMobile()

  return (
    <VStack w="full">
      <SkeletonText
        noOfLines={6}
        size="xl"
        skeletonHeight="72px"
        w="full"
        minH="72px"
        h="auto"
        startColor="brand.wildBlueYonder"
        endColor="brand.primary"
        speed={0.5}
        fadeDuration={2}
        textAlign="justify"
        isLoaded={!isLoading}
      >
        {isOnMobile && useMobileTable ? (
          <SimpleGrid gap="20px" width="full" {...mobileTableGridProps}>
            {mobileContent}
          </SimpleGrid>
        ) : (
          <VStack w="full" spacing="8px">
            <Grid
              w="full"
              columnGap="24px"
              autoColumns="1fr"
              autoFlow="column"
              px="24px"
              {...headerStyles}
            >
              {columns.map(({ header, columnStyles }, index) => (
                <GridItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  py="16px"
                  {...columnStyles}
                >
                  {typeof header === 'string' ? (
                    <Text fontFamily="DM Sans Medium" fontSize="16px" color="brand.darkBlue100">
                      {header}
                    </Text>
                  ) : (
                    header()
                  )}
                </GridItem>
              ))}
            </Grid>
            {data.map((row) => (
              <Grid
                key={row.id}
                w="full"
                columnGap="24px"
                autoColumns="1fr"
                autoFlow="column"
                minH="72px"
                px="24px"
                py="12px"
                borderRadius="12px"
                color="brand.darkBlue100"
                overflow="hidden"
                {...(onRowClick && {
                  _hover: { bg: 'brand.blue20' },
                })}
                bgColor={row.id === highlightedRowId ? 'brand.blue40' : '#F5F9FE'}
                {...(highlighEmergencyDemands &&
                  row.listingType?.demandType === DemandType.Emergency && { bg: '#FDEABF' })}
                {...rowStyles}
              >
                {columns.map(({ cell, columnStyles, isActionColumn }, index) => (
                  <GridItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    h="full"
                    {...columnStyles}
                    position="relative"
                    {...(!isActionColumn &&
                      onRowClick && {
                        onClick: () => onRowClick?.(row),
                      })}
                  >
                    <Flex w="full" h="full" align="center">
                      {cell?.(row)}
                    </Flex>
                  </GridItem>
                ))}
              </Grid>
            ))}
          </VStack>
        )}
      </SkeletonText>
    </VStack>
  )
}
