import { Circle, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { IconCrown } from '@tabler/icons-react'
import { OfferStatus, useOffersQuery } from 'generated/generated-graphql'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { UserAvatar } from 'components/common/Avatar/UserAvatar'
import { Tooltip } from 'components/common/Tooltip'
import { GridTable } from 'components/table/GridTable'
import { MobileTableCard } from 'components/table/MobileTableCard'
import { useAuth } from 'providers/UserProvider'
import { getFormattedDateWithTime } from 'utils/date'
import { getLocaleFormattedPrice } from 'utils/price'

export const MyOffersTable = ({ demandId }: { demandId: number }) => {
  const { push } = useRouter()
  const { userData } = useAuth()
  const { data: offersListData, loading } = useOffersQuery({
    variables: { input: { demandId, userId: userData!.id } },
    skip: !userData,
    fetchPolicy: 'cache-and-network',
  })

  return (
    <VStack w="full" align="start">
      <Flex w="full" borderBottomWidth="1px" borderBottomColor="#CCCCCC" py="8px">
        <Text fontFamily="DM Sans SemiBold" fontSize="20px">
          Moje ponuky
        </Text>
      </Flex>

      {loading || offersListData?.offers?.length !== 0 ? (
        <GridTable
          data={offersListData?.offers ?? []}
          isLoading={loading}
          headerStyles={{ templateColumns: '1fr 3fr 1fr 1fr' }}
          rowStyles={{ templateColumns: '1fr 3fr 1fr 1fr' }}
          onRowClick={({ id }) => {
            void push(Route.OfferDetail({ id }))
          }}
          columns={[
            {
              header: 'Dodávateľ',
              cell: ({ contractor, status }) => (
                <HStack position="relative">
                  {status === OfferStatus.Won && (
                    <Tooltip content="Vítazná ponuka">
                      <Circle
                        size="30px"
                        backgroundColor="brand.blue100"
                        position="absolute"
                        top="-20px"
                        left="-30px"
                        border="2px solid white"
                      >
                        <IconCrown stroke={1.5} size="18px" color="white" />
                      </Circle>
                    </Tooltip>
                  )}
                  <UserAvatar
                    email={contractor.companyEmail}
                    name={contractor.companyName}
                    src={contractor.avatarUrl}
                  />
                  <Text fontFamily="DM Sans SemiBold" color="brand.blue100" noOfLines={1}>
                    {contractor.companyName ?? `${contractor?.firstName} ${contractor?.lastName}`}
                  </Text>
                </HStack>
              ),
            },
            {
              header: 'Názov ponuky',
              cell: ({ name }) => name,
            },
            {
              header: 'Cenová ponuka',
              cell: ({ priceList, approximatePrice }) => (
                <Text>
                  {(priceList?.totalAmount || approximatePrice) &&
                    getLocaleFormattedPrice(priceList?.totalAmount ?? approximatePrice!)}
                </Text>
              ),
            },
            {
              header: 'Dátum vytvorenia',
              cell: ({ createdAt }) => getFormattedDateWithTime(createdAt),
            },
          ]}
          mobileContent={offersListData?.offers?.map(
            ({ id: offerId, contractor, status, name, priceList, approximatePrice, createdAt }) => (
              <MobileTableCard
                key={offerId}
                actionButtons={{
                  buttons: [
                    {
                      href: Route.OfferDetail({ id: offerId }),
                      text: 'Detail ponuky',
                    },
                  ],
                }}
                content={[
                  {
                    title: () => (
                      <HStack position="relative">
                        {status === OfferStatus.Won && (
                          <Tooltip content="Vítazná ponuka">
                            <Circle
                              size="30px"
                              backgroundColor="brand.blue100"
                              position="absolute"
                              top="-20px"
                              left="-30px"
                              border="2px solid white"
                            >
                              <IconCrown stroke={1.5} size="18px" color="white" />
                            </Circle>
                          </Tooltip>
                        )}
                        <UserAvatar
                          email={contractor.companyEmail}
                          name={contractor.companyName}
                          src={contractor.avatarUrl}
                        />
                        <Text fontFamily="DM Sans SemiBold" color="brand.blue100" noOfLines={1}>
                          {contractor.companyName ??
                            `${contractor?.firstName} ${contractor?.lastName}`}
                        </Text>
                      </HStack>
                    ),
                    isFullWidth: true,
                  },
                  {
                    title: 'Názov',
                    caption: name,
                    isFullWidth: true,
                  },
                  {
                    title: 'Cenová ponuka',
                    caption:
                      (priceList?.totalAmount || approximatePrice) &&
                      getLocaleFormattedPrice(priceList?.totalAmount ?? approximatePrice!),
                  },
                  {
                    title: 'Dátum vytvorenia',
                    caption: getFormattedDateWithTime(createdAt),
                  },
                ]}
              />
            ),
          )}
        />
      ) : null}
    </VStack>
  )
}
