import React from 'react'
import {
  Button,
  Divider,
  Flex,
  GridItem,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { IconCircleX, IconCrown, IconPencil, IconPlus } from '@tabler/icons-react'
import {
  AdditionalData,
  DemandStatus,
  DemandType,
  FieldLabel,
  useDemandByIdQuery,
} from 'generated/generated-graphql'
import { map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { DemandTimeHorizon, DemandTimeHorizonLabelMapper } from 'constants/demand'
import { AttachmentList } from 'components/AttachmentList'
import { GoogleEmbedMap } from 'components/common/GoogleEmbedMap'
import { RestrictedAccessInfo } from 'components/common/RestrictedAccessInfo'
import { DemandHeading } from 'components/DemandHeading'
import { Card } from 'components/ui/Card'
import { useAuth } from 'providers/UserProvider'
import { getFormattedDate, getFormattedDateWithTime } from 'utils/date'
import { SelectWinnerModal } from './Modals/SelectWinnerModal'
import { MyOffersTable } from './MyOffersTable'
import { OffersTable } from './OffersTable'

interface FieldArrayValue {
  [key: string]: string | { label: string; value: string }
}

const renderFieldArray = (additionalData: {
  fieldArrayValues: { values: FieldArrayValue[] }
  fieldLabels: FieldLabel[]
}) => {
  const hasMoreThanOneItem = additionalData.fieldArrayValues.values.length > 1

  return additionalData.fieldArrayValues.values.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {additionalData.fieldLabels?.map(
        ({ key, label }, index) =>
          item[key] && (
            <React.Fragment key={label}>
              {index === 0 ? (
                <GridItem colSpan={2}>
                  <Text fontFamily="DM Sans SemiBold" color="brand.darkBlue80">
                    {(item[key] as { value?: string })?.value ?? String(item[key]) ?? ''}
                  </Text>
                </GridItem>
              ) : (
                <>
                  <Text fontFamily="DM Sans Medium" color="brand.darkBlue80">
                    {label}
                  </Text>
                  {Array.isArray(item[key]) ? (
                    <Text>
                      {/* @ts-ignore */}
                      {(item[key] as { value: string }[]).map((val) => val.value).join(' | ')}
                    </Text>
                  ) : (
                    <Text>
                      {(item[key] as { value?: string })?.value ?? String(item[key]) ?? ''}
                    </Text>
                  )}
                </>
              )}
            </React.Fragment>
          ),
      )}
      {hasMoreThanOneItem && (
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>
      )}
    </React.Fragment>
  ))
}

export const renderAdditionalData = (additionalData: AdditionalData) => {
  switch (additionalData.type) {
    case 'text':
      return (
        additionalData?.values?.[0] && (
          <React.Fragment key={additionalData.label}>
            <Text fontFamily="DM Sans Medium" color="brand.darkBlue80">
              {additionalData.label}
            </Text>
            <Text>{additionalData?.values?.[0] ?? ''}</Text>
          </React.Fragment>
        )
      )
    case 'dropdown':
      return (
        additionalData?.values && (
          <React.Fragment key={additionalData.label}>
            <Text fontFamily="DM Sans Medium" color="brand.darkBlue80">
              {additionalData.label}
            </Text>
            <Text>{additionalData?.values?.join(', ') ?? ''}</Text>
          </React.Fragment>
        )
      )

    case 'fieldArray':
      return renderFieldArray(
        additionalData as {
          fieldArrayValues: { values: FieldArrayValue[] }
          fieldLabels: FieldLabel[]
        },
      )
    case 'checkbox':
      return (
        additionalData?.values && (
          <React.Fragment key={additionalData.label}>
            <Text fontFamily="DM Sans Medium" color="brand.darkBlue80">
              {additionalData.label}
            </Text>
            <UnorderedList>
              {additionalData?.values?.map((val) => (
                <ListItem>{val}</ListItem>
              ))}
            </UnorderedList>
          </React.Fragment>
        )
      )
    default:
      return null
  }
}

export const DemandDetailRoute = ({ isPublicRoute = false }: { isPublicRoute?: boolean }) => {
  const { userData, isValidContractor } = useAuth()
  const { query } = useRouter()
  const { data: demandDetailData, refetch } = useDemandByIdQuery({
    variables: { id: Number(query.id) },
    skip: !query.id,
    fetchPolicy: 'cache-and-network',
  })

  const {
    isOpen: isSelectWinnerModalOpen,
    onOpen: onSelectWinnerModalOpen,
    onClose: onSelectWinnerModalClose,
  } = useDisclosure()

  if (!demandDetailData?.demandById) {
    return null
  }

  const {
    status,
    category,
    timeline,
    address,
    contactInfo,
    name,
    createdAt,
    listingType,
    description,
    additionalData,
    files,
    userId,
    numberOfOffers,
    closedAt,
    demandType,
    subCategories,
    tenderingEndsAt,
    apartmentBuildingInfo,
    propertyType,
  } = demandDetailData.demandById

  const isOwner = userData?.id === userId
  const isPublished = status === DemandStatus.Published
  const isApartmentTypeDemand = demandType === DemandType.Appartmentbuildings
  const isEmergencyDemand = demandType === DemandType.Emergency
  const hasOffers = !!numberOfOffers && numberOfOffers !== 0
  const canShowSensitiveDetails = !isPublicRoute && (isOwner || isValidContractor)

  return (
    <VStack w="full" spacing="32px">
      <DemandHeading
        title={name}
        subTitle={`Dátum vytvorenia: ${getFormattedDate(createdAt)}`}
        actionButtons={
          <Stack
            w={{ base: 'full', md: 'fit-content' }}
            direction={{ base: 'column', md: 'row' }}
            spacing="8px"
          >
            {isOwner && isPublished && (
              <>
                <Link href={Route.App.Demand.EditDemand({ id: Number(query.id) })}>
                  <Button
                    w={{ base: 'full', md: 'auto' }}
                    leftIcon={<IconPencil stroke={1.5} />}
                    variant="outline"
                  >
                    Upraviť dopyt
                  </Button>
                </Link>
                <Button
                  w={{ base: 'full', md: 'auto' }}
                  leftIcon={hasOffers ? <IconCrown stroke={1.5} /> : <IconCircleX stroke={1.5} />}
                  onClick={onSelectWinnerModalOpen}
                >
                  {hasOffers ? 'Ukončiť dopyt & vybrať ponuku' : 'Ukončiť dopyt'}
                </Button>
              </>
            )}
            {isValidContractor && !isOwner && isPublished && (
              <Link
                href={Route.App.Demand.CreateOffer({ demandId: Number(query.id) })}
                style={{ width: '100%' }}
              >
                <Button w={{ base: 'full', md: 'auto' }} leftIcon={<IconPlus stroke={1.5} />}>
                  Vytvoriť ponuku
                </Button>
              </Link>
            )}
            {!isPublished && closedAt && (
              <Text color="brand.blue100" fontFamily="DM Sans Medium" fontSize="18px">
                Dopyt bol ukončený dňa: {getFormattedDate(closedAt)}
              </Text>
            )}
          </Stack>
        }
      />
      {tenderingEndsAt && (
        <Text w="full">
          Lehota na predkladanie ponúk do {getFormattedDateWithTime(tenderingEndsAt)}
        </Text>
      )}

      <Flex w="full" direction={{ base: 'column', md: 'row' }} gap="20px">
        <Card w="full">
          <VStack w="full" align="start" spacing="24px" divider={<Divider borderColor="#CCCCCC" />}>
            <VStack spacing="16px" align="start">
              <Text fontSize="20px" fontFamily="DM Sans SemiBold">
                Základné informácie
              </Text>
              {category && listingType && (
                <SimpleGrid columns={2} gap="8px">
                  {!isApartmentTypeDemand && (
                    <>
                      <Text color="brand.darkBlue80">Kategória</Text>
                      <Text>{category.name}</Text>
                    </>
                  )}
                  <Text color="brand.darkBlue80">Typ dopytu</Text>
                  <Text>{listingType.name}</Text>
                  {timeline && (
                    <>
                      <Text color="brand.darkBlue80">Termín realizácie</Text>
                      <Text>{DemandTimeHorizonLabelMapper[timeline as DemandTimeHorizon]}</Text>
                    </>
                  )}
                  {propertyType && (
                    <>
                      <Text color="brand.darkBlue80">Druh nehnuteľnosti</Text>
                      <Text>{propertyType}</Text>
                    </>
                  )}
                </SimpleGrid>
              )}
            </VStack>
            {subCategories && subCategories.length !== 0 && (
              <VStack spacing="16px" align="start">
                <Text fontSize="20px" fontFamily="DM Sans SemiBold">
                  Rozsah prác
                </Text>
                <VStack align="start">
                  <UnorderedList>
                    {subCategories.map(({ name }) => (
                      <ListItem>
                        <Text>{name}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </VStack>
              </VStack>
            )}
            <VStack spacing="16px" align="start">
              <Text fontSize="20px" fontFamily="DM Sans SemiBold">
                Popis
              </Text>
              <Text>{description ?? 'Bez popisu'}</Text>
            </VStack>
            {!isEmergencyDemand && (apartmentBuildingInfo || additionalData) && (
              <VStack w="full" spacing="16px" align="start">
                <Text fontSize="20px" fontFamily="DM Sans SemiBold">
                  {apartmentBuildingInfo ? 'Údaje o bytovom dome' : 'Technické špecifikácie'}
                </Text>
                {apartmentBuildingInfo ? (
                  <SimpleGrid columns={2} gap="8px">
                    <Text color="brand.darkBlue80">Konštrukcia bytoveho domu</Text>
                    <Text>{apartmentBuildingInfo.constructionMaterial}</Text>
                    <Text color="brand.darkBlue80">Stav bytoveho domy</Text>
                    <Text>{apartmentBuildingInfo.condition}</Text>
                    <Text color="brand.darkBlue80">Počet bytov</Text>
                    <Text>{apartmentBuildingInfo.numberOfApartmentUnits}</Text>
                    <Text color="brand.darkBlue80">Počet poschodí</Text>
                    <Text>{apartmentBuildingInfo.numberOfFloors}</Text>
                    <Text color="brand.darkBlue80">Počet vchodov</Text>
                    <Text>{apartmentBuildingInfo.numberOfEntrances}</Text>
                    <Text color="brand.darkBlue80">Rok výstavby</Text>
                    <Text>{apartmentBuildingInfo.yearOfBuilt}</Text>
                    <Text color="brand.darkBlue80">Výťah</Text>
                    <Text>{apartmentBuildingInfo.elevator ? 'Áno' : 'Nie'}</Text>
                    <Text color="brand.darkBlue80">Balkóny / lodžie</Text>
                    <Text>{apartmentBuildingInfo.balconyType}</Text>
                    <Text color="brand.darkBlue80">Predpokladané financovanie</Text>
                    <Text>{apartmentBuildingInfo.financing?.join(', ')}</Text>
                    <GridItem colSpan={2}>
                      <VStack spacing="4px" align="start">
                        <Text color="brand.darkBlue80">Rozsah prác</Text>
                        <VStack align="start">
                          <UnorderedList>
                            {apartmentBuildingInfo.additionalInformations?.map((infoText) => (
                              <ListItem>
                                <Text>{infoText}</Text>
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </VStack>
                      </VStack>
                    </GridItem>
                  </SimpleGrid>
                ) : (
                  <SimpleGrid w="full" columns={2} gap="8px">
                    {map(additionalData, (value) => renderAdditionalData(value))}
                  </SimpleGrid>
                )}
              </VStack>
            )}
          </VStack>
        </Card>
        <VStack w="full" spacing="20px">
          <Card title="Kontaktné údaje">
            {canShowSensitiveDetails && contactInfo ? (
              <VStack align="start" spacing="16px" divider={<Divider />}>
                <SimpleGrid w="full" columns={2} gap="8px">
                  {apartmentBuildingInfo && (
                    <>
                      <Text color="brand.darkBlue80">Názov správcu bytového domu</Text>
                      <Text>{apartmentBuildingInfo.landLordName}</Text>
                      <Text color="brand.darkBlue80">IČO</Text>
                      <Text>{apartmentBuildingInfo.ico}</Text>
                    </>
                  )}
                  <Text color="brand.darkBlue80">Meno</Text>
                  <Text>
                    {contactInfo?.firstName} {contactInfo?.lastName}
                  </Text>
                  <Text color="brand.darkBlue80">Email</Text>
                  <Text>{contactInfo?.email}</Text>
                  <Text color="brand.darkBlue80">Telefón</Text>
                  <Text>{contactInfo?.phoneNumber}</Text>
                </SimpleGrid>
                {contactInfo?.companyName && (
                  <SimpleGrid w="full" columns={2} gap="8px">
                    <Text color="brand.darkBlue80">Názov firmy</Text>
                    <Text>{contactInfo.companyName}</Text>
                    <Text color="brand.darkBlue80">IČO</Text>
                    <Text>{contactInfo.ico}</Text>
                    <Text color="brand.darkBlue80">DIČ</Text>
                    <Text>{contactInfo.dic}</Text>
                    {contactInfo.icDph && (
                      <>
                        <Text color="brand.darkBlue80">IČ DPH</Text>
                        <Text>{contactInfo.icDph ?? '-'}</Text>
                      </>
                    )}
                  </SimpleGrid>
                )}
              </VStack>
            ) : (
              <RestrictedAccessInfo />
            )}
          </Card>
          {address && (
            <Card title="Miesto realizácie">
              {canShowSensitiveDetails ? (
                <>
                  <Text fontSize="12px">{address.formatted}</Text>
                  <GoogleEmbedMap googlePlacesId={address.placeId} />
                </>
              ) : (
                <>
                  <Text fontSize="12px">
                    {address.cityRegion} | {address.postCode} {address.city}
                  </Text>
                  <RestrictedAccessInfo />
                </>
              )}
            </Card>
          )}
          <Card maxW={{ base: 'full', lg: '590px' }} title="Prílohy">
            <AttachmentList files={files} />
          </Card>
        </VStack>
      </Flex>
      {!isPublicRoute && (
        <>
          {isValidContractor && !isOwner && <MyOffersTable demandId={Number(query.id)} />}
          {isOwner && <OffersTable demandId={Number(query.id)} />}
          <SelectWinnerModal
            hasOffers={hasOffers}
            demandId={Number(query.id)}
            isOpen={isSelectWinnerModalOpen}
            onClose={onSelectWinnerModalClose}
            onSubmitSuccess={() => {
              void refetch()
            }}
          />
        </>
      )}
    </VStack>
  )
}
