import { Box, Circle, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { IconCrown } from '@tabler/icons-react'
import { OfferStatus, useOffersQuery } from 'generated/generated-graphql'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Route } from 'constants/common/routes'
import { UserAvatar } from 'components/common/Avatar/UserAvatar'
import { Tooltip } from 'components/common/Tooltip'
import { GridTable } from 'components/table/GridTable'
import { MobileTableCard } from 'components/table/MobileTableCard'
import { getFormattedDateWithTime } from 'utils/date'
import { getLocaleFormattedPrice } from 'utils/price'

export const OffersTable = ({ demandId }: { demandId: number }) => {
  const { push } = useRouter()
  const { data: offersListData, loading } = useOffersQuery({
    variables: { input: { demandId } },
    fetchPolicy: 'network-only',
  })

  return (
    <VStack w="full" align="start">
      <Flex w="full" borderBottomWidth="1px" borderBottomColor="#CCCCCC" py="8px">
        <Text fontFamily="DM Sans SemiBold" fontSize="20px">
          Ponuky
        </Text>
      </Flex>

      {loading || offersListData?.offers?.length !== 0 ? (
        <GridTable
          data={offersListData?.offers ?? []}
          isLoading={loading}
          onRowClick={({ id }) => {
            void push(Route.OfferDetail({ id }))
          }}
          columns={[
            {
              header: 'Dodávateľ',
              cell: ({ contractor, status }) => (
                <HStack position="relative">
                  {status === OfferStatus.Won && (
                    <Tooltip content="Vítazná ponuka">
                      <Circle
                        size="30px"
                        backgroundColor="brand.blue100"
                        position="absolute"
                        top="-20px"
                        left="-30px"
                        border="2px solid white"
                      >
                        <IconCrown stroke={1.5} size="18px" color="white" />
                      </Circle>
                    </Tooltip>
                  )}
                  <UserAvatar
                    email={contractor.companyEmail}
                    name={contractor.companyName}
                    src={contractor.avatarUrl}
                  />
                  <Text fontFamily="DM Sans SemiBold" color="brand.blue100" noOfLines={1}>
                    {contractor.companyName ?? `${contractor?.firstName} ${contractor?.lastName}`}
                  </Text>
                </HStack>
              ),
            },
            {
              header: 'Názov ponuky',
              cell: ({ name }) => <Text noOfLines={1}>{name}</Text>,
            },
            {
              header: 'Cenová ponuka',
              cell: ({ priceList, approximatePrice }) => (
                <Text>
                  {(priceList?.totalAmount || approximatePrice) &&
                    getLocaleFormattedPrice(priceList?.totalAmount ?? approximatePrice!)}
                </Text>
              ),
            },
            {
              header: 'Dátum vytvorenia',
              cell: ({ createdAt }) => getFormattedDateWithTime(createdAt),
            },
          ]}
          mobileContent={offersListData?.offers?.map(
            ({ id: offerId, contractor, status, name, priceList, approximatePrice, createdAt }) => (
              <MobileTableCard
                key={offerId}
                actionButtons={{
                  buttons: [
                    {
                      href: Route.OfferDetail({ id: offerId }),
                      text: 'Detail ponuky',
                    },
                  ],
                }}
                content={[
                  {
                    title: () => (
                      <HStack position="relative">
                        {status === OfferStatus.Won && (
                          <Tooltip content="Vítazná ponuka">
                            <Circle
                              size="30px"
                              backgroundColor="brand.blue100"
                              position="absolute"
                              top="-20px"
                              left="-30px"
                              border="2px solid white"
                            >
                              <IconCrown stroke={1.5} size="18px" color="white" />
                            </Circle>
                          </Tooltip>
                        )}
                        <UserAvatar
                          email={contractor.companyEmail}
                          name={contractor.companyName}
                          src={contractor.avatarUrl}
                        />
                        <Text fontFamily="DM Sans SemiBold" color="brand.blue100" noOfLines={1}>
                          {contractor.companyName ??
                            `${contractor?.firstName} ${contractor?.lastName}`}
                        </Text>
                      </HStack>
                    ),
                    isFullWidth: true,
                  },
                  {
                    title: 'Názov',
                    caption: name,
                    isFullWidth: true,
                  },
                  {
                    title: 'Cenová ponuka',
                    caption:
                      (priceList?.totalAmount || approximatePrice) &&
                      getLocaleFormattedPrice(priceList?.totalAmount ?? approximatePrice!),
                  },
                  {
                    title: 'Dátum vytvorenia',
                    caption: getFormattedDateWithTime(createdAt),
                  },
                ]}
              />
            ),
          )}
        />
      ) : (
        <VStack w="full" spacing="48px" py="32px">
          <Box display="block">
            <Image
              src="/icons/no-data.svg"
              width={398}
              height={200}
              layout="intrinsic"
              alt="prazdna-tabulka"
            />
          </Box>
          <Text fontFamily="DM Sans Medium" fontSize="24px">
            Pre tento dopyt zatiaľ nie sú dostupné žiadne ponuky.
          </Text>
          <Text fontSize="16x">
            Dôvodom môže byť, že váš dopyt patrí do kategórie odvetvia, pre ktoré sú dodávateľské
            firmy sezónne viac vyťažené.
          </Text>
        </VStack>
      )}
    </VStack>
  )
}
