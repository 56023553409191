import { ReactNode } from 'react'
import {
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  GridItemProps,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconArrowRight, IconLockOpen } from '@tabler/icons-react'
import { Colors } from 'theme/constants'

interface CardItem {
  title: string | (() => ReactNode)
  caption?: ReactNode
  isFullWidth?: boolean
  href?: string
  isExternalLink?: boolean
  gridItemProps?: GridItemProps
}

interface ActionButton {
  href?: string
  text: string
  props?: ButtonProps
}
interface ActionButtons {
  buttons: ActionButton[]
  gridPlacement?: GridItemProps
  layout?: 'vertical' | 'horizontal'
}

interface MobileTableCardProps extends Omit<FlexProps, 'contextMenu' | 'content'> {
  content: CardItem[]
  actionButtons?: ActionButtons
  contextMenu?: ReactNode
}

export const MobileTableCard = ({
  actionButtons,
  content,
  contextMenu,
  ...rest
}: MobileTableCardProps) => (
  <Flex
    position="relative"
    direction="column"
    bg="brand.ghostWhite"
    border="1px #cccccc solid"
    shadow="primary"
    px="16px"
    py="16px"
    borderRadius="12px"
    {...rest}
  >
    {contextMenu && (
      <Flex position="absolute" right="16px">
        {contextMenu}
      </Flex>
    )}
    <Grid
      width="full"
      templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}
      gap="16px"
    >
      {content?.map(
        ({ title, href, caption, isExternalLink, isFullWidth, gridItemProps }, index) => (
          <GridItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...(isFullWidth && { colSpan: { base: 2, md: 1 } })}
            {...gridItemProps}
          >
            <VStack align="start" spacing="6px">
              {title && typeof title === 'string' && (
                <Text
                  fontSize="13px"
                  fontFamily="DM Sans Semibold"
                  lineHeight="20px"
                  // textTransform="uppercase"
                >
                  {title}
                </Text>
              )}
              {title && typeof title === 'function' && title?.()}
              {caption &&
                // eslint-disable-next-line no-nested-ternary
                (typeof caption === 'string' ? (
                  href ? (
                    <Link
                      isTruncated
                      href={href}
                      {...(isExternalLink && {
                        icon: <IconLockOpen color="brand.ultramarine" height="12px" />,
                      })}
                      color="brand.ultramarine"
                      fontFamily="Proxima Nova Semibold"
                      fontSize="15px"
                    >
                      {caption}
                    </Link>
                  ) : (
                    <Text color="brand.purpleNavy" fontSize="15px" lineHeight="20px" noOfLines={3}>
                      {caption}
                    </Text>
                  )
                ) : (
                  caption
                ))}
            </VStack>
          </GridItem>
        ),
      )}
      {actionButtons && actionButtons.buttons.length !== 0 && (
        <GridItem colSpan={{ base: 2, md: 3 }} {...actionButtons.gridPlacement}>
          <Flex
            w="full"
            direction={actionButtons.layout === 'vertical' ? 'column' : 'row'}
            gridGap="8px"
          >
            {actionButtons.buttons.map(({ href, text, props }) =>
              href ? (
                <Link key={href} href={href} style={{ width: '100%' }}>
                  <Button
                    w="full"
                    variant="outline"
                    mt={{ base: '10px', md: 0 }}
                    rightIcon={<IconArrowRight stroke={1.5} color={Colors.brand.blue100} />}
                    iconSpacing="6px"
                    {...props}
                  >
                    {text}
                  </Button>
                </Link>
              ) : (
                <Button
                  key={text}
                  w="full"
                  variant="outline"
                  mt={{ base: '10px', md: 0 }}
                  rightIcon={<IconArrowRight stroke={1.5} color={Colors.brand.blue100} />}
                  iconSpacing="6px"
                  {...props}
                >
                  {text}
                </Button>
              ),
            )}
          </Flex>
        </GridItem>
      )}
    </Grid>
  </Flex>
)
