import { ReactNode } from 'react'
import { Flex, Text, VStack } from '@chakra-ui/react'

export const DemandHeading = ({
  title,
  subTitle,
  actionButtons,
}: {
  title: string
  subTitle?: string
  actionButtons?: ReactNode
}) => (
  <Flex
    w="full"
    direction={{ base: 'column', md: 'row' }}
    align={{ base: 'start', md: 'center' }}
    justifyContent={{ base: 'start', md: 'space-between' }}
    gap="16px"
  >
    <VStack align="start" spacing="4px">
      <Text fontSize="20px" fontFamily="DM Sans SemiBold">
        {title}
      </Text>
      {subTitle && <Text lineHeight="20px">{subTitle}</Text>}
    </VStack>
    {actionButtons}
  </Flex>
)
