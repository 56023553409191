import { Box, Button, Flex, Stack, Text, VStack } from '@chakra-ui/react'
import { IconLockAccess } from '@tabler/icons-react'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { useAuth } from 'providers/UserProvider'

export const RestrictedAccessInfo = () => {
  const { userData } = useAuth()
  const isOnMobile = useIsOnMobile()

  return (
    <Stack w="full" direction="row" align="center" spacing="32px" py="16px">
      <Box w={isOnMobile ? '64px' : '120px'} h={isOnMobile ? '64px' : '120px'}>
        <IconLockAccess size={isOnMobile ? '64px' : '120px'} stroke={1} color="#8080A5" />
      </Box>
      <VStack>
        <Text fontFamily="DM Sans Semibold">
          {userData
            ? 'Viac informacií iba pre dodávateľov s aktívnym predplatným'
            : 'Viac informacií iba pre prihlásených používateľov'}
        </Text>

        <Flex w="full" justify={{ base: 'center', lg: 'end' }}>
          <Link href={userData ? Route.ProfilePages.SubscriptionDetails() : Route.SignUp()}>
            <Button variant="link">{userData ? 'Zvoliť predplatné' : 'Registrovať sa'}</Button>
          </Link>
        </Flex>
      </VStack>
    </Stack>
  )
}
